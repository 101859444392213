import styled from '@emotion/styled'
import { Props as ImageProps } from 'app/components/Common/Image'
import { motion } from 'framer-motion'
import React, { memo } from 'react'
import { useInView } from 'react-intersection-observer'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export interface Props {
  description?: string
  image?: ImageProps
  label?: string
}

export const Service = memo(function Service({
  description,
  image,
  label,
}: Props) {
  const [ref, isVisible] = useInView({ threshold: 0.25 })
  const variants = {
    visible: {
      opacity: 1,
      scale: 1,
    },
    hidden: {
      opacity: 0,
      scale: 0.8,
    },
  }

  return (
    <Container
      ref={ref}
      animate={isVisible ? 'visible' : 'hidden'}
      transition={{
        duration: 0.5,
      }}
      variants={variants}
    >
      <LeftSide>
        {label ? <Title>{label}</Title> : null}
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
      </LeftSide>
      <RightSide>{image ? <LazyLoadImage {...image} /> : null}</RightSide>
    </Container>
  )
})

const Container = styled(motion.div)`
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  width: 50%;
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  &:nth-of-type(4n + 3),
  &:nth-of-type(4n + 4) {
    > div {
      &:first-of-type {
        order: 2;
        &:before {
          border-right: 0.9375rem solid
            ${({ theme }) => theme.colors.variants.neutralLight2};
          border-left: 0;
          right: auto;
          left: -0.9375rem;
        }
      }
    }
  }
  &:nth-of-type(3n + 2),
  &:nth-of-type(8n + 3) {
    background: ${({ theme }) => theme.colors.variants.primaryLight1};

    > div {
      &:before {
        border-right-color: ${({ theme }) =>
          theme.colors.variants.primaryLight1} !important;
        border-left-color: ${({ theme }) =>
          theme.colors.variants.primaryLight1} !important;
      }
    }
  }

  @media (max-width: 1023px) {
    width: 100%;
    &:nth-of-type(odd) {
      background: ${({ theme }) =>
        theme.colors.variants.neutralLight2} !important;
    }
    &:nth-of-type(even) {
      background: ${({ theme }) =>
        theme.colors.variants.primaryLight1} !important;
      > div {
        &:before {
          border-top-color: ${({ theme }) =>
            theme.colors.variants.primaryLight1};
        }
      }
    }
    &:nth-of-type(4n + 3),
    &:nth-of-type(4n + 4) {
      > div {
        &:first-of-type {
          &:before {
            border-left: 1.125rem solid transparent;
            border-right: 1.125rem solid transparent;
            right: 50%;
            left: auto;
            transform: translateX(50%);
          }
        }
        &:last-of-type {
          order: 2;
        }
      }
    }
    &:nth-of-type(3n + 2),
    &:nth-of-type(8n + 3) {
      > div {
        &:before {
          border-right-color: transparent !important;
          border-left-color: transparent !important;
        }
      }
    }
  }
`

const LeftSide = styled.div`
  width: 50%;
  padding: 5.75rem 4.514vw;
  position: relative;
  &:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 1.125rem solid transparent;
    border-left: 0.9375rem solid
      ${({ theme }) => theme.colors.variants.neutralLight2};
    border-bottom: 1.125rem solid transparent;
    position: absolute;
    top: 50%;
    right: -0.9375rem;
    z-index: 3;
    transform: translateY(-50%);
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding: 3rem 1.9375rem;
    &:before {
      border-left: 1.125rem solid transparent;
      border-right: 1.125rem solid transparent;
      border-top: 0.9375rem solid
        ${({ theme }) => theme.colors.variants.neutralLight2};
      top: auto;
      bottom: -2.0625rem;
      right: 50%;
      transform: translateX(50%);
    }
  }
`

const Title = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  line-height: 1.75rem;
`

const Description = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.5rem;
  margin-top: 1.25rem;
`

const RightSide = styled.div`
  width: 50%;
  padding-bottom: 60%;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.1;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding-bottom: 50%;
  }
`
